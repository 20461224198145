/* HandGestureRecognition.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .webcam {
    border: 2px solid #007bff;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .prediction {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #343a40;
  }
  
  .switch-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .switch-button:hover {
    background-color: #0056b3;
  }
  